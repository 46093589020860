import styled from "styled-components";
import View360, { EquirectProjection, ViewChangeEvent } from "@egjs/react-view360";
import "@egjs/react-view360/css/view360.min.css";

import Modal from "../controls/Modal"
import { useMemo } from "react";

interface RenderPreviewModalProps {
  onClose : () => void;
  render : string;
}

const RenderPreviewModal : React.FC<RenderPreviewModalProps> = (p) => {
  const projection = useMemo(() => new EquirectProjection({
    src : p.render
  }), [p.render]);


  return (
    <Modal
      title="Rendu"
      onClose={p.onClose}
    >
      <Container>
        <View360
          className="is-16by9"
          projection={projection}
        />

      </Container>
    </Modal>
  )
}

export default RenderPreviewModal;

const Container = styled.div`
  position: relative;
  width : calc(min(100vh/9*16, 100vw)*0.8);
  height : calc(min(100vh, 100vw/16*9)*0.8);
`

const RenderImage = styled.img`
  width: 100%;
  height: 100%;
`