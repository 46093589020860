import { Asset, Interaction, useInteractionsState, useUgla3dModel, useUgla3dState, useUgla3dStateManager } from "@ugla-france/ugla-3d-library";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Accordeon from "../controls/Accordeon";
import typography from "../../contants/typography";
import colors from "../../contants/colors";
import ProductCard from "../atoms/ProductCard";
import { getTag } from "../../tools/get-tag";
import ColorCard from "../atoms/ColorCard";

interface ColorsPanelProps {

}

const ColorsPanel : React.FC<ColorsPanelProps> = (p) => {
  const stateManager = useUgla3dStateManager();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const [interactions, setInteractions] = useState<Interaction<number>[]>([]);

  const model = useUgla3dModel();

  useEffect(() => {
    const colorInteractions = model.interactions().filter(i => getTag('type', i.tags || []) === 'color');
    setInteractions(colorInteractions);
  }, [key, model])

  const handleInteraction = (id : string) => {
    stateManager.interact(id);
  }


  return (
    <Container>
      <Title>Couleurs</Title>
      <Slider>
        {
          interactions.map(i => (
            <ColorCard interaction={i}/>
          ))
        }
      </Slider>
    </Container>
  );
}

export default ColorsPanel;

const Container = styled.div`
  flex : 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  margin-left : 1rem;
  ${typography.mediumTitle};
`

const Slider = styled.div`
  flex : 1;
  display: flex;
  overflow: auto;
`