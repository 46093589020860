import axios from "axios";
import configProvider from "./configprovider"

const url = configProvider('STATIC_API_URL').replace(/\/$/, '');
const _space = configProvider('STATIC_SPACE') ? configProvider('STATIC_SPACE') + '/' : '';
const key = configProvider('STATIC_ACCESS_KEY', localStorage.getItem('token') || '');

export const nameFromKey = (key : string) => {
  return key.replace(/\/+$/g, '').split('/').pop() || '';
}

export const cleanUrlPath = (path : string) => {
  return path.replace(/\/+/g, '/');
}

const storageProvider = {
  listAll : async (prefix ?: string, space : string = _space) : Promise<StorageFile[]> => {
    const directory = prefix ? prefix : '';
    const path = cleanUrlPath(`/list/${space}${directory}`);

    const response = await axios.get(`${url}${path}`, {headers : {Authorization : `Bearer: ${key}`}});
    return response.data.data;
  },

  getOne : async (name : string, space : string = _space) : Promise<string> => {
    const path = cleanUrlPath(`/${space}${name.replace(space, '')}`);

    const response = await axios.get(`${url}${path}`, {headers : {Authorization : `Bearer: ${key}`}});
    return response.data;
  },

  exists : async (url : string, space : string = _space) : Promise<boolean> => {
    try {
      const response = await axios.get(url, {headers : {Authorization : `Bearer: ${key}`}});
      return response.status >= 200 || response.status < 400;
    }
    catch(error) {
      return false;
    }
  },

  store : async (name : string, content : string, onOverwrite ?: (name : string) => Promise<boolean>, space : string = _space) : Promise<boolean> => {
    const form = new FormData();
    const fileName = nameFromKey(name);
    const file = new File([content], fileName);
    form.append('file', file);

    const path = cleanUrlPath('/' + space + name.replace(space, '').split('/').slice(0, -1).join('/') + '/');
    const completePathUrl = `${url}${path}`;
    const completeUrl = completePathUrl + fileName;

    // If a callback to validate overwrite is provided, test if file exists
    if(onOverwrite) {
      if(await storageProvider.exists(completeUrl)) {
        console.log('toto')
        if(!(await onOverwrite(name))) {
          console.log('inside')
          return false;
        }
        console.log('after')
      }
    }

    const response = await axios.put(completePathUrl, form, {headers : {Authorization : `Bearer: ${key}`}});
    return !!response.data;
  }
}

export default storageProvider;

export type StorageFile = {
  "id": string;
  "key": string;
  "lastModified": string;
  "size": number;
  "directory": boolean;
}
