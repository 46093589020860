import styled from "styled-components";
import colors from "../../contants/colors";

import UGLALogo from "../../assets/images/UGLALogo.png";
import Tabs from "../controls/Tabs";
import InspirationsSubpanel from "./subpanels/InspirationsSubpanel";
import { Link } from "react-router-dom";

import { BiSolidBook, BiSolidImage, BiSolidHappy } from "react-icons/bi";
import SelectionPanel from "./SelectionPanel";
import ProjectsPanel from "./ProjectsPanel";


const Navigation : React.FC = () => {
  return (
    <Container>
      <Header>
        <Link to="/"><Logo src={UGLALogo} alt="UGLA" /></Link>
      </Header>
      <StyledTabs
        names={['Inspiration', 'Mon projet', 'Mes projets ']}
        icons={[<BiSolidBook />, <BiSolidImage />, <BiSolidHappy />]}
      >
        <InspirationsSubpanel />
        <SelectionPanel />
        <ProjectsPanel />
      </StyledTabs>
    </Container>
  )
}

export default Navigation;

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const Header = styled.div`
  background-color: ${colors.backgroundLight};
  height: 11.8rem;
  display: flex;
  align-items: center;
  padding: 3.4rem;
`

const Logo = styled.img`
  width: 11.8rem;
  height: 6.8rem;
`

const StyledTabs = styled(Tabs)`
  .TabsHeader {
    border-bottom: none;
    display: flex;
    background-color: ${colors.backgroundLight};
  }

  .TabsTab {
    flex: 1;
    text-align: center;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: ${colors.backgroundLightest};
    color: ${colors.lightText};
    font-size: 1.4rem;
    border: 1px solid ${colors.backgroundLight};
  }

  .TabsTab.selected {
    background-color: ${colors.background};
    color: ${colors.text};
    border: none;
  }
`