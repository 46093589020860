import { useSceneState, useUgla3dModel, useUgla3dStateManager } from "@ugla-france/ugla-3d-library";
import { getTag } from "../../tools/get-tag";
import styled from "styled-components";
import ProductCard from "../atoms/ProductCard";
import BasketProductCard from "../atoms/BasketProductCard";

interface BasketPanelProps {

}

const BasketPanel : React.FC<BasketPanelProps> = (p) => {
  const products = useSceneState().filter(so => !!getTag('product_id', so.tags || []));
  const stateManager = useUgla3dStateManager();

  const handleRemove = (id : string) => {
    stateManager.removeObject(id);
  }

  return (
    <Container>
      {
        products.map(p => (
          <BasketProductCard product={p} onRemove={() => handleRemove(p.id)}/>
        ))
      }
    </Container>
  )
}

export default BasketPanel;

const Container = styled.div`
  flex : 1;
  overflow: auto;
`