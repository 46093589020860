import styled from "styled-components";
import Typography from "../../contants/typography";
import Modal from "../controls/Modal"
import { useState } from "react";
import typography from "../../contants/typography";
import colors from "../../contants/colors";

interface SaveFileModalProps {
  name ?: string;
  onClose ?: () => void;
  onSubmit ?: (name : string) => void;
}

const SaveFileModal : React.FC<SaveFileModalProps> = (p) => {
  const [name, setName] = useState<string>(p.name || '');

  const handleSubmit = () => {
    if(name) {
      p.onSubmit?.(name)
    }
  }

  return (
    <Modal
      title="Enregistrer le dossier"
      onClose={p.onClose}
      controls={
        [<Button onClick={handleSubmit}>Enregistrer</Button>]
      }
    >
      <Input value={name} onChange={e => setName(e.currentTarget.value)}/>
    </Modal>
  )
}

export default SaveFileModal;

const Button = styled.div`
  background-color: ${colors.secondary};
  border-radius: 1rem;
  border : 1px solid ${colors.secondary};
  padding : 1rem;
  ${typography.label}
  color : ${colors.negativeText};

  &:active {
    color : ${colors.text};
    background-color: ${colors.background};
  }
`

const Input = styled.input`
  ${typography.mediumTitle}
  width : 100%;
  padding : 2rem;
`