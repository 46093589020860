import { Asset } from "@ugla-france/ugla-3d-library";
import styled from "styled-components";
import { useDrag } from 'react-dnd'
import typography from "../../contants/typography";
import colors from "../../contants/colors";
import { getTag } from "../../tools/get-tag";

interface ProductCardProps {
  product : Asset;
}

const ProductCard : React.FC<ProductCardProps> = (p) => {
  const [_, dragRef] = useDrag(
    () => ({
      type: 'product',
      item: p.product,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []
  )


  return (
    <Card key={p.product.url} ref={dragRef}>
      {
        getTag('thumbnail', p.product.tags, '') ?
          <Image src={getTag('thumbnail', p.product.tags)}/> :
          null
      }
      <DescriptionContainer>
        <ProductName>
          {getTag('description', p.product.tags) || p.product.url.split('/').pop()?.replace('.glb', '')?.replace(/_/g, ' ')}
        </ProductName>
        <ProductCode>
          {(p.product.tags || []).find(t => t.key === 'product_id')?.value || ''}
        </ProductCode>
      </DescriptionContainer>
    </Card>
  )
}


export default ProductCard;

const Card = styled.div`
  display: flex;
  margin : 0.5rem;
  padding : 0.5rem;
  border-radius: 0.5rem;
`

const DescriptionContainer = styled.div`
  margin-left : 1rem;
  border-bottom: 1px solid ${colors.backgroundLight};
  flex : 1;
`

const ProductName = styled.div`
  ${typography.label}
`

const ProductCode = styled.div`
  ${typography.smallLabel};
  color : ${colors.lightText};
`

const Image = styled.img`
  width : 9.3rem;
  height : 9.3rem;
  border-radius: 1rem;
  border: 1px solid ${colors.backgroundLight};
  padding: 1rem
`