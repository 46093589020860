import styled from "styled-components";
import colors from "../../contants/colors";

interface ButtonProps {
  children : React.ReactNode;
  icon : React.ReactNode;
  iconPosition ?: 'left' | 'right';
  onClick : () => void;
}

const Button : React.FC<ButtonProps> = ({children, icon, iconPosition, onClick}) => {
  return (
    <StyledButton onClick={onClick}>
      {(!iconPosition || iconPosition === 'left') && icon && <LeftIconContainer>{icon}</LeftIconContainer>}
      {children}
      {iconPosition === 'right' && icon && <RightIconContainer>{icon}</RightIconContainer>}
    </StyledButton>
  )
}

export default Button;

const StyledButton = styled.button`
  background-color: ${colors.secondary};
  color : ${colors.negativeText};
  border : 1px solid ${colors.secondary};
  border-radius : 1rem;
  font: normal normal bold 1.8rem/2.4rem Roboto;  padding : 2rem;
  letter-spacing: 0px;
  text-transform: uppercase;
  padding : 1rem 2rem;
  border-radius : 10rem;
  display : flex;
  align-items : center;
  justify-content : center;

  &:active {
    background-color: ${colors.background};
    color : ${colors.secondary};
  }
`

const LeftIconContainer = styled.div`
  margin-right : 1rem;
`

const RightIconContainer = styled.div`
  margin-left : 1rem;
`