import styled from "styled-components";
import Navigation from "../components/panels/Navigation";
import Layout from "../layout/Layout";
import colors from "../contants/colors";
import InspirationCard from "../components/atoms/InspirationCard";


import kitchen01 from "../assets/images/kitchen_01.png";
import kitchen02 from "../assets/images/kitchen_02.png";
import kitchen03 from "../assets/images/kitchen_03.png";
import kitchen04 from "../assets/images/kitchen_04.png";
import kitchen05 from "../assets/images/kitchen_05.png";
import kitchen06 from "../assets/images/kitchen_06.png";


const inspirations = [
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 01", image: kitchen01 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 02", image: kitchen02 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 03", image: kitchen03 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 04", image: kitchen04 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 05", image: kitchen05 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 06", image: kitchen06 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 07", image: kitchen01 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 08", image: kitchen02 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 09", image: kitchen03 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 10", image: kitchen04 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 11", image: kitchen05 },
  { link: "/editor?key=3d-demo-dev/demo_configurateur_v11.ugla", title: "Cuisine 12", image: kitchen06 },
]

const InspirationsPage : React.FC = () => {
  return (
    <Layout
      leftPanel={<Navigation />}
    >
      <Container>
        {
          inspirations.map((inspiration) => (
            <InspirationCard key={inspiration.link} link={inspiration.link} title={inspiration.title} image={inspiration.image} />
          ))
        }
      </Container>
    </Layout>
  )
}

export default InspirationsPage;

const Container = styled.div`
  flex-grow: 1;
  background-color: ${colors.backgroundDark};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 6rem;
  padding: 6rem;
  justify-items: center;
  overflow: auto;
`
