import { useEffect } from "react";
import { useUgla3dModel } from "@ugla-france/ugla-3d-library";
import { Interaction } from "@ugla-france/ugla-3d-library";
import { useUgla3dStateManager } from "@ugla-france/ugla-3d-library";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getTag } from "../../../tools/get-tag";
import ColorCard from "../../atoms/ColorCard";

interface ColorsSubpanelProps {
  type : 'colors' | 'textures';
}

const ColorsSubpanel : React.FC<ColorsSubpanelProps> = (p) => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const [interactions, setInteractions] = useState<Interaction<number>[]>([]);

  const model = useUgla3dModel();

  useEffect(() => {
    const colorInteractions = model.interactions().filter(i => getTag('type', i.tags || []) === 'color' && getTag('preview', i.tags || [])?.[0] === '#');
    const texturesInteractions = model.interactions().filter(i => getTag('type', i.tags || []) === 'color' && getTag('preview', i.tags || [])?.[0] !== '#');
    setInteractions(p.type === 'colors' ? colorInteractions : texturesInteractions);
  }, [key, model])

  return (
    <Container>
      <Slider>
        {
          interactions.map(i => (
            <ColorCard interaction={i}/>
          ))
        }
      </Slider>
    </Container>
  );
}

export default ColorsSubpanel;

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const Slider = styled.div`
  flex : 1;
  display: flex;
  overflow: auto;
`