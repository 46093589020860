import styled from "styled-components";
import colors from "../../contants/colors";
import typography from "../../contants/typography";
import { useRef, useState } from "react";
import SaveFileModal from "../modals/SaveFileModal";
import { createSearchParams, useSearchParams } from "react-router-dom";
import storageProvider from "../../providers/storageprovider";
import configProvider from "../../providers/configprovider";
import { useUgla3dStateManager } from "@ugla-france/ugla-3d-library";
import ConfirmModal from "../modals/ConfirmModal";
import { BiSolidSave } from "react-icons/bi";

interface SaveButtonProps {

}

const SaveButton : React.FC<SaveButtonProps> = (p) => {
  const stateManager = useUgla3dStateManager();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showOverwritePrompt, setShowOverwritePrompt] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const key = searchParams.get('key') || '';
  const name = (key.split('/').pop() || '').replace('.ugla', '');
  const [newName, setNewName] = useState<string>('');
  const resolveRef = useRef<((response : boolean) => void) | undefined>(undefined);

  const handleClick = () => {
    setShowModal(true);
  }

  const onOverwrite = async () => {
    return new Promise<boolean>((_resolve) => {
      resolveRef.current = _resolve;
      setShowOverwritePrompt(true);
    })
  }

  const handleSubmit = async (name : string) => {
    setShowModal(false);
    setNewName(name);
    const fileName = configProvider('STATIC_SPACE') + '/' + name + '.ugla';
    const response = await storageProvider.store(fileName, JSON.stringify(stateManager.toModel().getContent()), onOverwrite);
    if(response) {
      const newSearchParams = createSearchParams(searchParams);
      newSearchParams.set('key', fileName);
      setSearchParams(newSearchParams)
    }
  }

  return (
    <>
      <Button onClick={handleClick}>
        Enregistrer&nbsp;&nbsp;&nbsp;<BiSolidSave />
      </Button>
      {
        showOverwritePrompt ?
          <ConfirmModal
            title={`Dossier existant`}
            message={`Le dossier ${newName} existe. Voulez-vous le remplacer?`}
            onClose={() => setShowOverwritePrompt(false)}
            onSubmit={resolveRef.current}
          /> :
        showModal ?
          <SaveFileModal name={name} onClose={() => setShowModal(false)} onSubmit={handleSubmit}/> :
          null
      }
    </>
  );
}

export default SaveButton;

const Button = styled.button`
  flex : 1;
  background-color: ${colors.secondary};
  color : ${colors.negativeText};
  border : 1px solid ${colors.secondary};
  border-radius : 1rem;
  font: normal normal bold 1.8rem/2.4rem Roboto;  padding : 2rem;
  letter-spacing: 0px;
  text-transform: uppercase;
  padding : 1rem 2rem;
  border-radius : 10rem;
  display : flex;
  align-items : center;
  justify-content : center;

  &:active {
    background-color: ${colors.background};
    color : ${colors.secondary};
  }
`