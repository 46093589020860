import styled from "styled-components";
import clsx from "clsx";
import colors from "../../contants/colors";
import { ReactNode, useState } from "react";
import typography from "../../contants/typography";

interface TabsProps {
  className ?: string;
  names : string[];
  icons ?: ReactNode[];
  children : ReactNode[];
}

const Tabs : React.FC<TabsProps> = (p) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const icons = p.icons || [];

  return (
    <Container className={p.className}>
      <Header className={'TabsHeader'}>
        {
          p.names.map((name, index) => (
            <Tab key={name} className={clsx('TabsTab', {selected : index === selectedTab})} onClick={() => setSelectedTab(index)}>
              {(icons || [])[index] ? <Icon>{icons[index]}</Icon> : null}{name}
            </Tab>
          ))
        }
      </Header>
      {
        p.children.map((child, index) => (
          <TabPanel key={p.names[index]} style={index === selectedTab ? {display : 'block'} : {display : 'none'}}>
            {child}
          </TabPanel>
        ))
      }
    </Container>
  )
}

export default Tabs;

const Container = styled.div`
  flex : 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  margin-bottom : 0.2rem;
  border-bottom : 1px solid ${colors.backgroundLight};
`

const Tab = styled.div`
  color : ${colors.lightText};
  ${typography.mediumTitle};
  padding : 1rem;
  cursor : pointer;
  display: flex;

  &.selected {
    color : ${colors.text};
  }
`

const TabPanel = styled.div`
  flex : 1;
  overflow: auto;
  position: relative;
`

const Icon = styled.div`
  margin-right: 1rem;
`