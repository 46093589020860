import styled from "styled-components";
import { DndProvider } from 'react-dnd'
import { MultiBackend } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'


interface LayoutProps {
  children : React.ReactNode;
  leftPanel ?: React.ReactNode;
  rightPanel ?: React.ReactNode;
  bottomPanel ?: React.ReactNode;
}


const EditorPage : React.FC<LayoutProps> = (p) => {
  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <Container>
        <LeftPanel>
          {p.leftPanel}
        </LeftPanel>
        <CenterPanel>
          <ContentPanel>
          {
            p.children
          }
          </ContentPanel>
          {
            p.bottomPanel && (
              <BottomPanel>
                {p.bottomPanel}
              </BottomPanel>
            )
          }
        </CenterPanel>
        {
          p.rightPanel && (
            <RightPanel>
              {p.rightPanel}
            </RightPanel>
          )
        }
      </Container>
    </DndProvider>
  );
}

export default EditorPage;


const Container = styled.div`
  position : relative;
  width : 100vw;
  height : 100vh;
  display: flex;
`

const LeftPanel = styled.div`
  height : 100%;
  width : 40rem;
  display: flex;
  flex-direction: column;
`

const RightPanel = styled.div`
  width: 8.7rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
`

const CenterPanel = styled.div`
  flex : 1;
  display: flex;
  flex-direction: column;
`

const ContentPanel = styled.div`
  flex : 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const BottomPanel = styled.div`
  height : 27.8rem;
`
