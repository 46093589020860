import styled from "styled-components";
import colors from "../../contants/colors";
import { Link } from "react-router-dom";

interface InspirationCardProps {
  title : string;
  image : string;
  icon : React.ReactNode;
  link : string;
}

const InspirationCategoryCard : React.FC<InspirationCardProps> = (p) => {
  return (
    <Link to={p.link}>
      <Card>
        <Image src={p.image} alt={p.title} />
        <Label><Icon>{p.icon}</Icon>{p.title}</Label>
      </Card>
    </Link>
  )
}

export default InspirationCategoryCard;

const Card = styled.div`
  display: inline-block;
  width: 16rem;
  height: 16rem;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Label = styled.div`
  height: 4.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.backgroundDark};
  padding: 1rem;
  color: ${colors.negativeText};
  font-size: 1.4rem;
  text-transform: uppercase;
` 

const Icon = styled.div`
  margin-right: 1rem;
`