import styled from "styled-components";
import colors from "../../../contants/colors";
import Input from "../../controls/TextInput";
import Button from "../../controls/Button";
import { BiSolidCamera } from "react-icons/bi";
import { useEffect, useState } from "react";
import axios from "axios";
import configProvider from "../../../providers/configprovider";
import { useSessionContext } from "../../../context/SessionContext";
import storageProvider, { StorageFile } from "../../../providers/storageprovider";
import { useUgla3dStateManager } from "@ugla-france/ugla-3d-library";
import RenderPreviewModal from "../../modals/RenderPreviewModal";


const RenderSubpanel : React.FC = () => {
  const [name, setName] = useState<string>('');
  const [renderList, setRenderList] = useState<StorageFile[]>([]);
  const {sessionId} = useSessionContext();
  const stateManager = useUgla3dStateManager();
  const [previewRender, setPreviewRender] = useState<string | null>(null);

  const handleRender = async () => {
    if(name === '') {
      alert('Veuillez entrer un nom pour le rendu');
      return;
    }

    const stored = await storageProvider.store(`/${sessionId}/stored.ugla`, JSON.stringify(stateManager.toModel().getContent()), () => Promise.resolve(true), configProvider('STATIC_RENDER_SPACE'));

    if(!stored) {
      alert('Erreur lors de l\'enregistrement du fichier');
      return;
    }

    const file = configProvider('STATIC_API_URL') + '/' + configProvider('STATIC_RENDER_SPACE') + '/' + sessionId + '/stored.ugla';

    

    axios.post(configProvider('API_URL') + '/tasks', {
      type : 'render',
      action : 'init-from-file',
      priority : 1,
      meta : [{
        key : 'file',
        value : file
      }, {
        key : 'resolutionX',
        value : '4096'
      }, {
        key : 'resolutionY',
        value : '2048'
      }, {
        key : 'type',
        value : '360'
      }, {
        key : 'outputName',
        value : name
      }, {
        key : 'userFolder',
        value : `${sessionId}/render/${name}`
      }]
    })
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      storageProvider.listAll('/' + sessionId + '/render/', configProvider('STATIC_RENDER_SPACE')).then((files) => {
        setRenderList(files.filter((file) => file.key.endsWith('.png')));
      });
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const handlePreviewRender = (key : string) => {
    setPreviewRender(configProvider('STATIC_API_URL') + '/' + key);
  }

  return (
    <Container>
      <InputColumn>
        <Input label="Nom du rendu" value={name} onChange={(value) => setName(value)} /><br />
        <Button icon={<BiSolidCamera />} iconPosition="right" onClick={handleRender}>Rendu</Button>
      </InputColumn>
      <RenderList>
        {renderList.map((file) => (
          <RenderItem key={file.key} onClick={() => handlePreviewRender(file.key)} >
            <RenderImage src={configProvider('STATIC_API_URL') + '/' + file.key} alt={file.key.split('/').pop()} />
            <RenderItemName>{file.key.split('/').pop()}</RenderItemName>
          </RenderItem>
        ))}
      </RenderList>
      {previewRender && <RenderPreviewModal render={previewRender} onClose={() => setPreviewRender(null)} />}
    </Container>
  )
}

export default RenderSubpanel;

const Container = styled.div`
  display : flex;
  width: 100%;
  height: 100%;
  background-color: ${colors.backgroundDark};
  border : none;
`

const InputColumn = styled.div`
  padding : 2rem;
`

const RenderList = styled.div`
  padding : 2rem;
  display : flex;
  flex-direction : row;
  gap : 1rem;
`

const RenderItem = styled.div`
  padding : 1rem;
  background-color: ${colors.backgroundLight};
  border-radius: 0.5rem;
`

const RenderItemName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`

const RenderImage = styled.img`
  width: 20rem;
  height: 20rem;
  object-fit: cover;
`