import styled from "styled-components";
import colors from "../../contants/colors";
import { Link } from "react-router-dom";

interface InspirationCardProps {
  title : string;
  image : string;
  link : string;
}

const InspirationCard : React.FC<InspirationCardProps> = (p) => {
  return (
    <Link to={p.link}>
      <Card>
        <Image src={p.image} alt={p.title} />
        <Label>{p.title}</Label>
      </Card>
    </Link>
  )
}

export default InspirationCard;

const Card = styled.div`
  display: inline-block;
  width: 38.6rem;
  height: 32.7rem;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Label = styled.div`
  height: 7.9rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.background};
  padding: 1rem;
  color: ${colors.text};
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
` 

const Icon = styled.div`
  margin-right: 1rem;
`