import styled from "styled-components";
import Typography from "../../contants/typography";
import Modal from "../controls/Modal"
import { useState } from "react";
import typography from "../../contants/typography";
import colors from "../../contants/colors";

interface ConfirmModalProps {
  title ?: string;
  message ?: string;
  yesMessage ?: string;
  noMessage ?: string;
  onClose ?: () => void;
  onSubmit ?: (answer : boolean) => void;
}

const ConfirmModal : React.FC<ConfirmModalProps> = (p) => {
  const handleSubmit = (response : boolean) => {
    p.onClose?.();
    p.onSubmit?.(response);
  }

  return (
    <Modal
      title={p.title}
      onClose={p.onClose}
      controls={
        [
          <Button key="no" onClick={() => handleSubmit(false)}>{p.noMessage || 'Non'}</Button>,
          <Button key="yes" onClick={() => handleSubmit(true)}>{p.yesMessage || 'Oui'}</Button>
        ]
      }
    >
      <Message>
        {p.message}
      </Message>
    </Modal>
  )
}

export default ConfirmModal;

const Button = styled.div`
  background-color: ${colors.secondary};
  border-radius: 1rem;
  border : 1px solid ${colors.secondary};
  padding : 1rem 2rem;
  ${typography.label}
  color : ${colors.negativeText};
  margin-left : 2rem;

  &:active {
    color : ${colors.text};
    background-color: ${colors.background};
  }
`

const Message = styled.div`
  margin-bottom : 3rem;
  ${typography.label}
`