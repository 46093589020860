import { ReactNode } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import colors from "../../contants/colors";
import typography from "../../contants/typography";

interface ModalProps {
  title ?: string;
  controls ?: ReactNode;
  onClose ?: () => void;
  children ?: ReactNode;
}

const Modal : React.FC<ModalProps> = (p) => {
  return createPortal(
      <Container onClick={p.onClose}>
        <Frame onClick={e => e.stopPropagation()}>
          {p.title && <Title>{p.title}</Title>}
          {p.children}
          {p.controls && <Controls>{p.controls}</Controls>}
        </Frame>
      </Container>,
      document.body
    );
}

export default Modal;

const Container = styled.div`
  position: fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  backdrop-filter : blur(0.8rem) brightness(0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Frame = styled.div`
  border-radius: 1rem;
  background-color: ${colors.background};
  padding : 4rem;
`

const Title = styled.div`
  ${typography.title};
  color : ${colors.text};
  margin-bottom : 1rem;
`

const Controls = styled.div`
  margin-top : 1rem;
  display: flex;
  justify-content : flex-end;
`