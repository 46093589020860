import styled from "styled-components";
import colors from "../../../contants/colors";
import Tabs from "../../controls/Tabs";
import ColorsPanel from "../ColorsPanel";
import ColorsSubpanel from "./ColorsSubpanels";

import { BiPalette, BiSolidChess, BiFilm } from "react-icons/bi";
import { MdEdit } from "react-icons/md";

const FurniturePanel = () => {
  return (
    <Container>
      <StyledTabs 
        names={['Couleurs', 'Textures', 'Informations', 'Animations']}
        icons={[<BiPalette />, <BiSolidChess />, <MdEdit />, <BiFilm />]}
      >
        <ColorsSubpanel type="colors"/>
        <ColorsSubpanel type="textures"/>
        <div></div>
        <div></div>
      </StyledTabs>
    </Container>
  )
}

export default FurniturePanel;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.backgroundDark};
`

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: 100%;

  & .TabsHeader {
    background-color: ${colors.backgroundMedium};
    border-bottom: 1px solid ${colors.backgroundDark};
  }

  & .TabsTab {
    color: ${colors.negativeText};
    font: normal normal normal 1.8rem/2.4rem Roboto;
    border-right: 1px solid ${colors.backgroundDark};
    min-width: 17rem;

    &.selected {
      background-color: ${colors.backgroundDark};
    } 
  }
`