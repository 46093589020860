import { Asset, useUgla3dModel, useUgla3dState, useUgla3dStateManager } from "@ugla-france/ugla-3d-library";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Accordeon from "../controls/Accordeon";
import typography from "../../contants/typography";
import colors from "../../contants/colors";
import ProductCard from "../atoms/ProductCard";

interface SelectionPanelProps {

}

const SelectionPanel : React.FC<SelectionPanelProps> = (p) => {
  const stateManager = useUgla3dStateManager();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const [assets, setAssets] = useState<Record<string, Asset[]>>({});
  const model = useUgla3dModel();

  useEffect(() => {
    const assets = 
      model.assets()
      .filter(asset => (asset.tags || []).find(tag => tag.key === 'category'))
      .reduce((dic, asset) => {
        const category = (asset.tags || []).find(tag => tag.key === 'category');
        if(!category) {return dic;}

        if(!dic[category.value]) {
          dic[category.value] = [];
        }
        dic[category.value].push(asset);
        return dic;
      }, {} as Record<string, Asset[]>);
    setAssets(assets);
  }, [key, model])

  return (
    <Container>
      <Accordeon
        panels={
          Object.entries(assets).map(([name, products]) => ({
            label : name,
            node : products.map(product => (
              <ProductCard key={product.url} product={product}/>
            ))
          }))
        }
      />
    </Container>
  );
}

export default SelectionPanel;

const Container = styled.div`
  flex : 1;
  overflow: auto;
`