import { Asset, Interaction, useUgla3dStateManager } from "@ugla-france/ugla-3d-library";
import styled from "styled-components";
import { useDrag } from 'react-dnd'
import typography from "../../contants/typography";
import colors from "../../contants/colors";
import { getTag } from "../../tools/get-tag";

interface ColorCardProps {
  interaction : Interaction<number>;
}

const ColorCard : React.FC<ColorCardProps> = (p) => {
  const stateManager = useUgla3dStateManager();
  const name = getTag('name', p.interaction.tags, '');
  const thumbnail = getTag('preview', p.interaction.tags, '') || "#EEEEEE";


  const handleClick = () => {
    stateManager.interact(p.interaction.id);
  }

  return (
    <Card key={p.interaction.id} onClick={handleClick}>
      {
        thumbnail[0] === '#' ?
          <ColorPreview style={{backgroundColor : thumbnail}}/> :
          <TexturePreview src={thumbnail}/>
      }
      <Name>
        {name}
      </Name>
    </Card>
  )
}


export default ColorCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin : 1rem;
  padding : 0.5rem;
  border-radius: 0.5rem;
`
const Name = styled.div`
  ${typography.label}
`
const TexturePreview = styled.img`
  background-color: ${colors.backgroundLight};
  width : 15rem;
  height : 10rem;
  border-radius: 0.5rem;
`

const ColorPreview = styled.div`
  background-color: ${colors.backgroundLight};
  width : 12rem;
  height : 12rem;
  border-radius: 12rem;
`