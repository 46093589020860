import styled from "styled-components";
import colors from "../../contants/colors";
import { BiMove, BiRotateLeft, BiSolidTrashAlt } from "react-icons/bi";

interface ItemMenuProps {
  onModeChange : (mode : 'move' | 'rotate') => void;
  onDelete : () => void;
  mode ?: 'move' | 'rotate';
}

const ItemMenu : React.FC<ItemMenuProps> = (p) => {
  return (
    <Container>
      <Button onClick={() => p.onModeChange('move')} className={p.mode === 'move' ? 'active' : ''}>
        <BiMove size={24} />
      </Button>
      <Button onClick={() => p.onModeChange('rotate')} className={p.mode === 'rotate' ? 'active' : ''}>
        <BiRotateLeft size={24} />
      </Button>
      <Button onClick={p.onDelete} className={'active'}>
        <BiSolidTrashAlt size={24} />
      </Button>
    </Container>
  );
};

export default ItemMenu;

const Container = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: ${colors.backgroundDark};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem;
  border-radius: 5.4rem
`;

const Button = styled.button`
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 4rem;
  background-color: ${colors.backgroundMedium};
  border: none;
  margin-bottom: 0.8rem;
  padding: 0%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-of-type {
    margin-bottom: 0rem;
  }

  &.active {
    background-color: ${colors.background};
    color: ${colors.text};
  }
`;
