import { createContext, useContext } from "react";

type SessionContextType = {
  sessionId : string;
}

const SessionContextInternal = createContext<SessionContextType>({sessionId : Date.now().toString()});

export const useSessionContext = () => {
  return useContext(SessionContextInternal);
}

const SessionContext = SessionContextInternal.Provider;

export default SessionContext;