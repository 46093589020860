import styled from "styled-components";
import { StorageFile } from "../../providers/storageprovider";
import colors from "../../contants/colors";
import { Link, useSearchParams } from "react-router-dom";
import typography from "../../contants/typography";

interface FileCardProps {
  file : StorageFile;
}

const FileCard : React.FC<FileCardProps> = (p) => {
  const [searchParams] = useSearchParams();
  const viewer = searchParams.get('viewer');

  return (
    <Link to={`/editor?key=${p.file.key}${viewer ? `&viewer=${viewer}` : ''}`}>
      <Container>
        <Label>{p.file.key.split('/').pop()?.replace('.ugla', '').replace(/[\-_]+/g, ' ')}</Label>
        <ModificationDate>{new Date(p.file.lastModified).toLocaleDateString()} {new Date(p.file.lastModified).toLocaleTimeString()}</ModificationDate>
      </Container>
    </Link>
  )
}

export default FileCard;

const Container = styled.div`
  cursor : pointer;
  margin-top : 2rem;
  margin-right : 2rem;
`


const Label = styled.div`
  overflow: hidden;
  text-transform: capitalize;
  color : ${colors.text};
  ${typography.label};
`

const ModificationDate = styled.div`
  color : ${colors.lightText};
  ${typography.smallLabel}
`