import styled from "styled-components";
import InspirationCategoryCard from "../../atoms/InspirationCategoryCard";

import chambre from "../../../assets/images/Chambre.png";
import cuisine from "../../../assets/images/Cuisine.png";
import salon from "../../../assets/images/Salon.png";
import sam from "../../../assets/images/SaM.png";
import sdb from "../../../assets/images/SdB.png";

import { BiSolidFridge, BiSolidBookReader, BiSolidBath, BiSolidPizza, BiSolidBed } from "react-icons/bi";


const InspirationsSubpanel : React.FC = () => {
  return (
    <Container>
      <InspirationCategoryCard link="/inspirations/cuisine" title="Cuisine" image={cuisine} icon={<BiSolidFridge />} />
      <InspirationCategoryCard link="/inspirations/salon" title="Salon" image={salon} icon={<BiSolidBookReader />} />
      <InspirationCategoryCard link="/inspirations/sdb" title="Salle de bain" image={sdb} icon={<BiSolidBath />} />
      <InspirationCategoryCard link="/inspirations/sam" title="Salle à manger" image={sam} icon={<BiSolidPizza />} />
      <InspirationCategoryCard link="/inspirations/chambre" title="Chambre" image={chambre} icon={<BiSolidBed />} />
    </Container>
  )
}

export default InspirationsSubpanel;


const Container = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  justify-items: center;
  align-items: center;
`
