import styled from "styled-components";
import Navigation from "../components/panels/Navigation";
import Layout from "../layout/Layout";

import background from "../assets/images/HomeBackground.png";

const HomePage : React.FC = () => {
  return (
    <Layout
      leftPanel={
        <Navigation />
      }
    >
      <Container>
        <Veil />
        <Line1>Bienvenue sur</Line1>
        <Line2>EASY RENDER</Line2>
        <Line3>Le Visual Asset Builder (VAB) d’UGLA</Line3>
      </Container>
    </Layout>
  )
}

export default HomePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  position: relative;
`

const Veil = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`

const Line1 = styled.div`
  font: normal normal 900 8rem/13rem Roboto;
  letter-spacing: 0px;
  color: #4479BB;
  position: relative;
`

const Line2 = styled.div`
  font: normal normal 900 14rem/13rem Roboto;
  letter-spacing: 0px;
  color: #4479BB;
  position: relative;
`

const Line3 = styled.div`
  font: normal normal normal 4rem/5rem Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-top: 4rem;
  position: relative;
`