import styled from "styled-components";
import SaveButton from "../atoms/SaveButton";
import { useEffect, useState } from "react";
import storageProvider, { StorageFile } from "../../providers/storageprovider";
import FileCard from "../atoms/FileCard";

const ProjectsPanel : React.FC = () => {
  const [files, setFiles] = useState<StorageFile[]>([]);

  useEffect(() => {
    storageProvider.listAll()
      .then(files => setFiles(files.filter(f => !f.directory)))
      .catch(() => {
        alert('Erreur de lecture des fichies')
      })
  }, [])

  return (
    <Container>
      <SaveButtonContainer>
        <SaveButton />
      </SaveButtonContainer>
      <FilesContainer>
        {
          files.map(file => (
            <FileCard key={file.key} file={file} />
          ))
        }
      </FilesContainer>
    </Container>
  )
}

export default ProjectsPanel;

const Container = styled.div`
  display : flex;
  flex-direction : column;
`

const SaveButtonContainer = styled.div`
  padding : 2rem;
  display : flex;
`

const FilesContainer = styled.div`
  padding : 2rem;
`