import { Asset, SceneObject } from "@ugla-france/ugla-3d-library";
import { Immutable } from 'immer';
import styled from "styled-components";
import { useDrag } from 'react-dnd'
import typography from "../../contants/typography";
import colors from "../../contants/colors";
import { getTag } from "../../tools/get-tag";
import TrashIcon from "../../assets/icons/TrashIcon";

interface BasketProductCardProps {
  product : Immutable<SceneObject<Asset>>;
  onRemove ?: () => void;
}

const BasketProductCard : React.FC<BasketProductCardProps> = (p) => {

  return (
    <Card>
      {
        getTag('thumbnail', p.product.tags, '') ?
          <Image src={getTag('thumbnail', p.product.tags)}/> :
          null
      }
      <DescriptionContainer>
        <ProductName>
          {getTag('description', p.product.tags) || p.product.asset.url.split('/').pop()?.replace('.glb', '')?.replace(/_/g, ' ')}
        </ProductName>
        <ProductCode>
          {(p.product.tags || []).find(t => t.key === 'product_id')?.value || ''}
        </ProductCode>
      </DescriptionContainer>
      <TrashIconContainer onClick={p.onRemove}>
        <TrashIcon color={colors.accent}/>
      </TrashIconContainer>
    </Card>
  )
}


export default BasketProductCard;

const Card = styled.div`
  display: flex;
  margin : 0.5rem;
  padding : 0.5rem;
  background-color: ${colors.backgroundLight};
  border-radius: 0.5rem;
`

const DescriptionContainer = styled.div`
  margin-left : 1rem;
  flex : 1;
`

const ProductName = styled.div`
  ${typography.label}
`

const ProductCode = styled.div`
  ${typography.smallLabel};
  color : ${colors.lightText};
`

const Image = styled.img`
  width : 5rem;
  height : 5rem;
  border-radius: 0.5rem;
`

const TrashIconContainer = styled.div`
  width : 2rem;
  margin-right : 1rem;
  margin-left : 1rem;
  cursor : pointer;
`