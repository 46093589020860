const colors = {
  text : '#000',
  lightText : 'rgba(0, 0, 0, 0.3)',
  background : '#FFF',
  backgroundLight : '#EAEAEA',
  backgroundLightest : '#F4F4F4',
  backgroundDark : '#000000',
  backgroundMedium : '#404040',
  accent : '#880000',
  secondary : '#4479BB',
  negativeText : '#FFFFFF',
  error : '#FF0000',
  select : '#4479BB'
}

export default colors;