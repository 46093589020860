import styled from "styled-components";
import colors from "../../contants/colors";

import { BiSolidBed, BiReflectVertical, BiVerticalBottom, BiVerticalTop, BiSolidDoorOpen, BiSolidRadio, BiSolidBulb, BiText, BiSolidCamera } from "react-icons/bi";

interface ToolButtonProps {
  selected : string;
  setSelected : (selected : string) => void;
}

const ToolsPanel : React.FC<ToolButtonProps> = (p) => {
  return (
    <Container>
      <ToolButton onClick={() => p.setSelected('Mobilier')} className={p.selected === 'Mobilier' ? 'selected' : ''}>
        <BiSolidBed />
      </ToolButton>
      <ToolLabel>Mobilier</ToolLabel>
      <ToolButton onClick={() => p.setSelected('Murs')} className={p.selected === 'Murs' ? 'selected' : ''}>
        <BiReflectVertical />
      </ToolButton>
      <ToolLabel>Murs</ToolLabel>
      <ToolButton onClick={() => p.setSelected('Sols')} className={p.selected === 'Sols' ? 'selected' : ''}>
        <BiVerticalBottom />
      </ToolButton>
      <ToolLabel>Sols</ToolLabel>
      <ToolButton onClick={() => p.setSelected('Plafond')} className={p.selected === 'Plafond' ? 'selected' : ''} >
        <BiVerticalTop />
      </ToolButton>
      <ToolLabel>Plafond</ToolLabel>
      <ToolButton onClick={() => p.setSelected('Fenêtres')} className={p.selected === 'Fenêtres' ? 'selected' : ''} >
        <BiSolidDoorOpen />
      </ToolButton>
      <ToolLabel>Fenêtres</ToolLabel>
      <ToolButton onClick={() => p.setSelected('Accessoires')} className={p.selected === 'Accessoires' ? 'selected' : ''} >
        <BiSolidRadio />
      </ToolButton>
      <ToolLabel>Accessoires</ToolLabel>
      <ToolButton onClick={() => p.setSelected('Eclairages')} className={p.selected === 'Eclairages' ? 'selected' : ''} >
        <BiSolidBulb />
      </ToolButton>
      <ToolLabel>Eclairages</ToolLabel>
      <ToolButton onClick={() => p.setSelected('Textes')} className={p.selected === 'Textes' ? 'selected' : ''} >
        <BiText />
      </ToolButton>
      <ToolLabel>Textes</ToolLabel>
      <ToolButton onClick={() => p.setSelected('Rendu')} className={p.selected === 'Rendu' ? 'selected' : ''} >
        <BiSolidCamera />
      </ToolButton>
      <ToolLabel>Rendu</ToolLabel>
    </Container>
  )
}

export default ToolsPanel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  background-color: ${colors.backgroundDark};
  align-items: center;
  border-left: 1px solid ${colors.backgroundMedium};

`

const ToolButton = styled.button`
  margin-top: 2rem;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  width: 6rem ;
  height: 6rem;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }

  &.selected {
    background-color: ${colors.background};
  }
`

const ToolLabel = styled.div`
  margin-top: 0.5rem;
  color : rgba(255, 255, 255, 0.6);
  font-size: 1.4rem;
`