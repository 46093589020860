const typography = {
  title : `
    font-family : 'Roboto';
    font-size : 4rem;
    font-weight : 700;
    padding-top : 2rem;
    padding-bottom : 4rem;
  `,

  mediumTitle : `
    font-family : 'Roboto';
    font-size : 2rem;
    font-weight : 700;
    padding-top : 1rem;
    padding-bottom : 1rem;
  `,

  label : `
    font-size: 1.5rem;
    padding-top : 1rem;
  `,

  smallLabel : `
    font-size: 1.2rem;
    padding-top : 0.5rem;
  `
}

export default typography;